import React from "react";

const SlideTitle = () => {
  return (
    <div className="indent">
      <h1>
        I Am
        <div className="slidingVertical">
          <span>Determined.</span>
          <span>Creative.</span>
          <span>Adaptable.</span>
          <span>Reliable.</span>
          <span>Logical.</span>
          <span>Developer.</span>
        </div>
      </h1>
    </div>
  );
};

export default SlideTitle;
