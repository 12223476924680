import React from "react";
import { Card } from "react-bootstrap";
import { FiExternalLink } from "react-icons/fi";
import { AiOutlineGithub } from "react-icons/ai";

const ProjectCard = ({ title, image, body, webLink, codeLink, alt }) => {
	return (
		<Card className="project-card">
			<Card.Img src={image} alt={alt} />
			<Card.Title className="p-2 text-center">{title}</Card.Title>
			<Card.Body>{body}</Card.Body>
			<div className="d-flex justify-content-center">
				{codeLink && (
					<a
						className="m-4 project-icon"
						href={codeLink}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={`${title} code link`}
					>
						<AiOutlineGithub />
					</a>
				)}
				{webLink && (
					<a
						className="m-4 project-icon"
						href={webLink}
						target="_blank"
						rel="noopener noreferrer"
						aria-label={`${title} link`}
					>
						<FiExternalLink />
					</a>
				)}
			</div>
		</Card>
	);
};

export default ProjectCard;
