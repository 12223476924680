import React, { useState } from "react";
import { AiOutlineGithub, AiFillLinkedin } from "react-icons/ai";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import "./navbar.scss";

const Navbar = () => {
	const [active, setActive] = useState("nav-menu--inactive");

	const toggleActive = () => {
		if (active === "nav-menu--active") return setActive("nav-menu--inactive");
		setActive("nav-menu--active");
	};

	return (
		<nav className="Navbar">
			<ul className="navlist">
				<button
					className="hamburger "
					onClick={toggleActive}
					aria-label="Hamburger menu"
				>
					<FiMenu />
				</button>

				<div className="nav-menu">
					<div className={`slide-container ${active}`}>
						<li className="nav-item">
							<a href="#About">About</a>
						</li>
						<li className="nav-item">
							<a href="#Skills">Skills</a>
						</li>
						<li className="nav-item">
							<a href="#Projects">Projects</a>
						</li>
					</div>
				</div>
			</ul>
			<ul className="nav-buttons">
				<li className="nav-item">
					<a
						href={"https://1drv.ms/w/s!AjmF7RCzj6E9vK0CDZvu4wJK5ijjJg?e=CgmWG3"}
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Dominick's Resume"
					>
						<BsFillBriefcaseFill className="social-buttons" />
					</a>
				</li>
				<li className="nav-item">
					<a
						href="https://www.linkedin.com/in/dominick-dejesus/"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Dominick's LinkedIn Profile"
					>
						<AiFillLinkedin className="social-buttons" alt="LinkedIn" />
					</a>
				</li>
				<li className="nav-item">
					<a
						href="https://github.com/DominickDeJesus/"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Dominick's Github Profile"
					>
						<AiOutlineGithub className="social-buttons" alt="Github" />
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
