import React from "react";
import { Container } from "react-bootstrap";

const AboutSlide = () => {
	return (
		<div className="about-slide" id="About">
			<h2 className="about-title">About</h2>
			<Container>
				<div className="">
					<p>
						I am a Junior full stack web developer from Miami that has always
						had a passion for learning and creating. As a kid it manifested as
						breaking apart old stereos to see how they worked and see if I could
						fix them. As a student, it was expressed by taking what I learned in
						class and seeing what I could build with it. And now as a
						professional in the industry, it is what fuels my desire to make a
						difference with my work and to never stop learning.
					</p>
				</div>
			</Container>
		</div>
	);
};

export default AboutSlide;
