import React from "react";
import SlideTitle from "../components/SlideTitle";
import Navbar from "./Navbar/Navbar";

const OpeningSlide = () => {
	return (
		<div className="opening-slide" id="top">
			<Navbar />
			<div className="intro">
				<h1>Hi, I Am Nick </h1>
				<SlideTitle />
			</div>
			<div className="intro-right"></div>
		</div>
	);
};

export default OpeningSlide;
