import React from "react";
import { TiArrowUp } from "react-icons/ti";
const BackButton = () => {
	return (
		<a href="#top" className="back-button" aria-label="Go to top">
			<TiArrowUp />
		</a>
	);
};

export default BackButton;
