import React from "react";
import { Image, Container } from "react-bootstrap";
import WordArt from "../resources/images/WordArt.png";
const SkillsSlide = () => {
	return (
		<div className="skills-slide" id="Skills">
			<h2 className="skills-title">Skills </h2>
			<div className="skills-content">
				<div className="skills-left">
					<div className="skills-paragraph">
						<Container>
							<p>
								I graduated from FIU with a degree in Computer Engineering and
								the Full Stack Web Development program at Wyncode Academy.
								Attending these schools not only gave me a strong understanding
								of programming fundamentals but they also improved my ability to
								teach myself how to find solutions. This helps me pick up new
								technologies quickly.
							</p>
							<p>
								I not only attended Wyncode as a student, but I also taught
								there as a Teaching Assistant and an Associate Instructor. This
								experience further strengthened my knowledge of development,
								reading and debugging someone else’s code, and finally being
								able to communicate complex programming ideas in plain english.
							</p>
							<p>
								I am currently employed at Deloitte as a solutions analyst where
								I develop applications with Angularjs and ServiceNow. These
								applications take a paper-based process and transition them into
								a streamlined workflow. By working on applications at various
								stages of development, I'm able to hit the ground running,
								whether I'm starting from scratch on a project or adding
								features to an application that's been in development for years.
							</p>
							<p>
								For more information, you can check out my resume with a
								comprehensive list of skills, projects, and experience by
								clicking the link{" "}
								<a href="https://1drv.ms/w/s!AjmF7RCzj6E9vK0CDZvu4wJK5ijjJg?e=CgmWG3">
									here
								</a>
								.
							</p>
						</Container>
					</div>
				</div>
				<div className="skills-right">
					<Image src={WordArt} className="w-100" alt="list of technologies" />
				</div>
			</div>
		</div>
	);
};

export default SkillsSlide;
