import React from "react";
import ProjectCard from "./ProjectCard";
import "./projects.scss";
import heart2heart from "../../resources/images/heart2heart.jpg";

const ProjectContainer = () => {
	const projects = [
		{
			title: "TrueStan",
			image: "https://i.imgur.com/m6rPW5T.gif",
			alt: "play page for truestan",
			body: "TrueStan is the ultimate test of your music fandom. It is a react web app game that will test you on how well you know an artist’s songs. Pick an artist, listen to the sound clip, and guess the song. The more songs you guess correctly, the less of the song you get to hear in the next round. Built with Reactjs and Expressjs, this web app uses iTunes API to get information about any artist the user enters.",
			webLink: "",
			// webLink: "https://truestan.herokuapp.com/",
			codeLink: "https://github.com/DominickDeJesus/TrueStan",
		},
		{
			title: "Planning Center Integration",
			image: "https://imgur.com/EuzjlnM.jpg",
			alt: "Spotify, Planning Center, and YouTube Logos",
			body: "This server will keep a spotify playlist up to date based on what songs are scheduled for the next Sunday in Planning Center.",
			webLink: "",
			codeLink:
				"https://github.com/DominickDeJesus/spotify-planning-center-script",
		},
		{
			title: "Supply Checking Server",
			image: "https://i.imgur.com/luGgxsV.jpg",
			alt: "Google Drive Logo",
			body: "This server will check the supply of a given the link to a product page. When the product has been restocked, the user will receive a text message with the link to the product. You can also text the server to get status updates, change the URL to watch, and see the last time the product was in stock. This project uses Puppeteer to scrape the page for information and uses Expressjs and the Twilio API to interact with the user via text message.",
			webLink: "",
			codeLink: "https://github.com/DominickDeJesus/supply-check-server",
		},
		{
			title: "StoryLine",
			image: "https://i.imgur.com/67xccYJ.gif",
			alt: "storyline dashboard page",
			body: "StoryLine is a web app built on the MERN stack to keep track of your goals and mark progress. You use it by creating an account and creating a goal with achievable milestones. With each day you can check off your daily tasks and reflect on that day's progress and see how many goals you have completed.",
			webLink: "",
			// webLink: "https://wyntankstoryline.herokuapp.com",
			codeLink: "https://github.com/DominickDeJesus/StoryLine",
		},
		{
			title: "Firebase Gist Manager",
			image: "https://imgur.com/bJIK4df.jpg",
			alt: "Front page of gist manager site",
			body: "A Firebase gist api that implements Github OAuth as well as CRUD operations for gists. The front end allows you to login and manage your own github gists.",
			webLink: "",
			// webLink: "https://gists.nickdejesus.com/",
			codeLink: "https://github.com/DominickDeJesus/firebase-gists-manager",
		},
		{
			title: "GoogleDrive Script",
			image: "https://i.imgur.com/GyRV635.png",
			alt: "Google Drive Logo",
			body: "This CLI script uses Google Drive’s api to manipulate file permissions. It is a fully functioning CLI that was built with Nodejs. You can feed this script a list of emails and it will add/remove the requested permission to the file/folder that you specify.",
			webLink: "",
			codeLink: "https://github.com/DominickDeJesus/gdrive-permission-script",
		},
		{
			title: "Heart 2 Heart",
			image: heart2heart,
			alt: "heart 2 heart page",
			body: "Heart 2 Heart is a social platform for bone marrow donors and those who have received those donations to share their stories. Our aim is to demystify the donation process and share the impact that donations have on those who need them. This app was coded for the Palm Beach Tech Hackathon 2020 to benefit Gift of Life.",
			webLink: "",
			// webLink: "https://heart--2--heart.herokuapp.com/",
			codeLink: "https://github.com/Cal9233/heart2heart",
		},
		{
			title: "Plot'Em",
			image: "https://i.imgur.com/wSjd5Dj.jpg",
			alt: "Chalkboard with FIU Logo on it",
			body: "Plot’em is a device that receives an image from a user and produces the picture autonomously on a blackboard. All parts, other than the motors, servo, wheels, bearings, and the rails, were 3D printed and designed using Fusion 360. An Arduino Uno is running open-source software called GRBL to control the movement of the device.",
			webLink: "",
			/*webLink:
				"https://issuu.com/fiuece/docs/ece_spring_2019_senior_design_bookl/18",*/
			codeLink: "",
		},
	];

	return (
		<div className="project-container" id="Projects">
			<h2 className="project-title">Projects</h2>
			<div className="card-container">
				{projects.map((project) => (
					<ProjectCard
						key={project.title}
						title={project.title}
						image={project.image}
						alt={project.alt}
						body={project.body}
						webLink={project.webLink}
						codeLink={project.codeLink}
					/>
				))}
			</div>
		</div>
	);
};

export default ProjectContainer;
