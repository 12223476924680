import React from "react";
import "./App.css";
import ProjectContainer from "./components/projects/ProjectContainer";
import OpeningSlide from "./components/OpeningSlide";
import SkillsSlide from "./components/SkillsSlide";
import AboutSlide from "./components/AboutSlide";
import BackButton from "./components/BackButton";
function App() {
	return (
		<>
			<OpeningSlide />
			<AboutSlide />
			<SkillsSlide />
			<ProjectContainer />
			<BackButton />
		</>
	);
}

export default App;
